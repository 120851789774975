import { Breakpoints, styled } from '@vw-marketing/us-components';

export const StyledSearchContainer = styled.div<any>`
  background: #ffffff;
  border-radius: 10px;
  padding: 25px 29px;
  width: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  @media (min-width: ${Breakpoints.b1280}px) {
    filter: none;
    height: 100%;
  }
`;
export const StyledTitleContainer = styled.div<any>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
`;
export const StyleZipContainer = styled.div<any>`
  ${({ theme }) => `
  padding-bottom: 18px;
  width:100%;
  @media (min-width: ${Breakpoints.b1280}px) {
    min-width: 290px;

  }
.input-field-label {
    display: none;
}
.input-field-icon  {
    display: flex;
    flex-flow: row-reverse;
    align-items: flex-end;

    .input-text-field {
        padding: 2px 42px;
        font-family: ${theme.fonts.headline[200]?.fontFamily
    };
          font-size: 16px;
          font-weight: ${theme.text.weight.regular};
          font-stretch: normal;
          font-style: normal;
          line-height: 36px;
          letter-spacing: normal;
          color: #000000;
          background: #FFFFFF;
          border: 1px solid #32ADFF;
          border-radius: 100px;
        &::placeholder {
          font-size: 18px;
        }
      }
    }

    .input-field-icon-button {
        left: 13px !important;

        svg {
            width: 18px;
            fill: #32ADFF!important;
        }
    }
`}
`;
export const StyledFiltersContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 25px;
`;
export const StyledSearchButtonContainer = styled.div<any>``;
export const StyledDropdownWrapper = styled.div`
  ${({ theme }) => `
  .dropdown-container {
      .dropdown-input {
        background: rgba(255, 255, 255, 0.85);
        border: 1px solid rgb(32, 58, 102, 0.1);
        border-radius: 2px;
        padding: 9px 14px;
        color: #001E50;
    }
    .dropdown-options-content {
      & > :first-child  {
        max-height: 320px;
        overflow-y: scroll;
        overflow-x: hidden;
        ::-webkit-scrollbar {
          width: 5px;
          border-radius: 2.5px;
        }
        ::-webkit-scrollbar-thumb {
            background: #d8d8d8;
            border-radius: 2.5px;
            height: 119px;
        }
      }
    }
    div {
        .dropdown-option-wrapper {
            font-family: ${theme.fonts.headline[200]?.fontFamily
    };
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            color: #000000;
            border-bottom: none;
            padding: 7.5px 13px;
            &:last-child {
                padding-bottom: 17px 13px;
            }
            &:hover {
                color: #37C1EF;
                font-weight: 700;
                background: #ffffff;
            }
        }
    }
  }
  `}
`;

export const StyledRangeSliderWrapper = styled.div<any>`
  ${({}) => `
    width: 100%;
    background: #fff;
    padding: 55px 12px 0px 12px;
`}
`;

export const StyledFilterLabelWrapper = styled.div`
  color: #001e50;
  padding-bottom: 16px;
`;
