import { createTagOverride, TextAlignment, TextAppearance, TextColor, TextCustom, TextCustomProps, TextLineHeight, TextSize, TextTag, TextWeight } from "@vw-marketing/us-components";


export const inputTextStyle: TextCustomProps = {
    appearance: TextAppearance.copy100,
    tag: TextTag.span,
    style: {
        fontSize: TextSize.TextSize14,
        lineHeight: TextLineHeight.TextLineHeight24,
        fontWeight: TextWeight.regular,
        color: TextColor.inherit
    }
}

export const filterLabelTextStyle: TextCustomProps = {
    appearance: TextAppearance.copy100,
    tag: TextTag.span,
    style: {
        fontSize: TextSize.TextSize14,
        lineHeight: TextLineHeight.TextLineHeight22,
        fontWeight: TextWeight.bold,
        color: TextColor.inherit
    }
}

export const searchButtonTextStyle: TextCustomProps = {
    appearance: TextAppearance.copy100,
    tag: TextTag.span,
    style: {
        fontSize: TextSize.TextSize14,
        lineHeight: TextLineHeight.TextLineHeight16,
        fontWeight: TextWeight.regular,
        color: TextColor.inherit
    }
}


const filterTitleTextStyle: TextCustomProps = {
    appearance: TextAppearance.copy100,
    style: {
      fontSize: TextSize.TextSize20,
      lineHeight: TextLineHeight.TextLineHeight25,
      color: TextColor.inherit,
      textAlign: TextAlignment.center
    },
    tag: TextTag.p,
  };
export const filterTitleTextOverrides = {
    p: createTagOverride(TextCustom, {...filterTitleTextStyle, tag: TextTag.p}),
    div: createTagOverride(TextCustom, {...filterTitleTextStyle, tag: TextTag.div}),
    span: createTagOverride(TextCustom, {...filterTitleTextStyle, tag: TextTag.strong}),
    strong: createTagOverride(TextCustom, {
      ...filterTitleTextStyle,
      style: {...filterTitleTextStyle.style, fontWeight: TextWeight.bold},
      tag: TextTag.strong
    }),
  };
