import {useApolloClient} from '@apollo/client';
import {Breakpoints, CustomImage, useIsMobile} from '@vw-marketing/us-components';
import React from 'react';
import {ErrorMessage} from '../components/error-message';
import {LoaderContainer} from '../components/loader-spinner';
import MastheadDescription from '../components/masthead-description';
import MastheadSearch from '../components/masthead-search';
import VideoBackground from '../components/video-background';
import {useFeatureAppConfig} from '../hooks/use-feature-app-config';
import {
  MastheadBackground,
  useFeatureAppContent,
} from '../hooks/use-feature-app-content';
import {MODELS_NAME} from '../store/queries/models-name';
import {ALL_MODELS_OPTION, AppConstants} from '../utils/app-constants';
import {backgroundMediaMapping, strToBoolean} from './helpers';
import {
  StyledMastheadAppMainContainer,
  StyledMastheadContentContainer,
  StyledMastheadContentWrapper,
  StyledMastheadImageWrapper,
  StyledMastheadSearchWrapper,
} from './styles';
import {fetchScene7Image} from '../store/fetch/fetch-image';

type AppStatus = 'loaded' | 'error' | 'loading';
const MastheadApp = () => {
  let {
    mastheadBackground = [],
    description = [],
    filtersContent = [],
  } = useFeatureAppContent();
  const faHostServerUrl = useFeatureAppConfig().faHostServerUrl;
  const { excludedModels } = useFeatureAppConfig();
  const isMobile = useIsMobile(Breakpoints.b960);
  const [appStatus, setAppStatus] = React.useState<AppStatus>('loading');
  const [modelsList, setModelsList] = React.useState<string[] | undefined>(
    undefined,
  );
  const [mastheadImage, setMastheadImage] = React.useState({
    mobile: '',
    desktop: '',
  });
  const client = useApolloClient();
  const TYPE_IMAGE = "image"
  const defaultBackground: MastheadBackground = {
    alt: 'No available image',
    backgroundType: 'image',
    desktopUrl: backgroundMediaMapping.image[TYPE_IMAGE].desktop,
    mobileUrl: backgroundMediaMapping.image[TYPE_IMAGE].mobile,
    imageHostUrl: faHostServerUrl || '',
    useScene7Src: 'false',
  };
  let background =
    mastheadBackground && mastheadBackground.length
      ? (mastheadBackground[0] as MastheadBackground)
      : defaultBackground;

  let backgroundType = background?.backgroundType || TYPE_IMAGE;

  const getModelsNameList = async () => {
        const result = await client.query({
      query: MODELS_NAME,
      variables: {},
    });
    if (result.error || !result.data) {
      throw new Error('No models data available' + result?.error?.message);
    }
    const models = result.data.getUsedModels.name;
    const newExcludedModelsArr = excludedModels?.map(item => item.id)
    const newModels = models.filter(item => !newExcludedModelsArr?.includes(item));
    setModelsList([ALL_MODELS_OPTION, ...newModels]);
    return Promise.resolve();
  };

  const getSrcImageConfig = async () => {
    if (backgroundType === TYPE_IMAGE) {
      let {
        mobileUrl = '',
        desktopUrl = '',
        scene7ImgExtension = '',
        useScene7Src = 'false',
        imageHostUrl = '',
      } = background;
      let mobile = imageHostUrl + mobileUrl;
      let desktop = imageHostUrl + desktopUrl;
      if (strToBoolean(useScene7Src)) {
        try {
          mobile = await fetchScene7Image(
            mobileUrl,
            scene7ImgExtension,
            imageHostUrl,
          );
          desktop = await fetchScene7Image(
            desktopUrl,
            scene7ImgExtension,
            imageHostUrl,
          );
        } catch {
          console.log('Missing Masthead Images');
        }
      }
      setMastheadImage({mobile, desktop});
    }
      return Promise.resolve();
  };

  React.useEffect(() => {
    setAppStatus('loading');
    Promise.all([getModelsNameList(), getSrcImageConfig()])
      .then(() => {
        setAppStatus('loaded');
      })
      .catch((error) => {
        console.log('Error Initializing Masthead FA:', error?.message);
        setAppStatus('error');
      });

    return () => {};
  }, []);

  if (appStatus === 'loading') return <LoaderContainer />;
  if (appStatus === 'error') return <ErrorMessage />;

  return (
    <StyledMastheadAppMainContainer className="masthead-app_main-container">
      <StyledMastheadImageWrapper className="masthead-app_background-wrapper">
        <div className="background-wrapper_container">
          {backgroundType && backgroundType in backgroundMediaMapping.video ? (
            <VideoBackground
              videoUrl={
                isMobile ? background?.mobileUrl : background?.desktopUrl
              }
            />
          ) : (
            <CustomImage
              src={isMobile ? mastheadImage.mobile : mastheadImage.desktop}
              alt={background?.alt || ''}
              srcPlaceholder={AppConstants.PlaceHolder960x432}
              srcErrorImage={faHostServerUrl + AppConstants.Ngw6Logo960x432}
            />
          )}
        </div>
      </StyledMastheadImageWrapper>
      <StyledMastheadContentContainer className="masthead-app_content-container">
        <StyledMastheadContentWrapper
          className="masthead-app_content-wrapper"
          alignment={description[0]?.alignment}
        >
          <MastheadDescription {...description[0]} />
        </StyledMastheadContentWrapper>
        <StyledMastheadSearchWrapper className="masthead-app_search-wrapper">
          <MastheadSearch {...filtersContent[0]} models={modelsList} />
        </StyledMastheadSearchWrapper>
      </StyledMastheadContentContainer>
    </StyledMastheadAppMainContainer>
  );
};

export default MastheadApp;
