import {Breakpoints, styled} from '@ngw6/us-components';

export const StyledDescriptionContainer = styled.div<any>`
  ${({textColor, cssStyle}) => `
    color: ${textColor};
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    @media (min-width: ${Breakpoints.b960}px) { 
      width: auto;
  }
  ${cssStyle}
`}
`;
export const StyledSubTitleTextWrapper = styled.div``;
export const StyledTitleTextWrapper = styled.div`
  ${({}) => `
    h1 > strong : {
        font-weight: bold !important;
    }
    @media (min-width: ${Breakpoints.b960}px) { 
        & > strong : {
            font-weight: bold!important;
        }
    }
    @media (min-width: ${Breakpoints.b1280}px) { 
        & > strong : {
            font-weight: bold!important;
        }
    }
`}
`;

export const StyledDescriptionTextWrapper = styled.div`
  display: none;
  @media (min-width: ${Breakpoints.b960}px) {
    display: block;
  }
`;
