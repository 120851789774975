import {
  Breakpoints,
  ChevronDown,
  TextCustom,
} from '@vw-marketing/us-components';
import _ from 'lodash';
import React from 'react';
import { useElementBounding } from '../../hooks/use-element-bounding-rect';
import { useTranslations } from '../../hooks/use-translations';
import {
  labelTextStyle,
} from './helpers';
import {
  StyledFilterDropdownButton,
  StyledFilterDropdownContainer,
  StyledFilterDropdownContent,
  StyledIcon,
} from './styles';

interface FilterContentDropdownProps {
  readonly label: string;
}
const FilterContentDropdown: React.FC<FilterContentDropdownProps> = (props) => {
  const {
    label,
    children,
  } = props;

  const [toggle, setToggle] = React.useState(false);
  const dropdownRef = React.useRef<HTMLDivElement | null>(null);
  const [elementBounding, dropdownContentRef] = useElementBounding();
  const  setContentTopOffset = React.useState(0)[1];
  const {  } =
    useTranslations().filterSection ;


  /**
   * Hide dropdown option box when user is interacting with another component
   * @param event
   */
  const handleMouseDown = (event: any) => {
    const isNotMobileViewport = window.matchMedia(
      `(min-width: ${Breakpoints.b560}px)`,
    ).matches;
    const otherDropdownsState = _.filter(
      dropdownRef?.current?.parentElement?.children,
      (child) =>
        child.contains(event.target) &&
        !child.classList.contains('filter-active-layer') &&
        !child.classList.contains('filter-close-button'),
    );
    const isClickedDropdown =
      !isNotMobileViewport && otherDropdownsState.length;
    const noContainerElement = isNotMobileViewport || isClickedDropdown;

    !dropdownRef?.current?.contains(event.target) &&
      noContainerElement &&
      setToggle(false);
  };

  const calculateContentOffset = () => {
    elementBounding && setContentTopOffset(elementBounding.bottom);
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', handleMouseDown, false);
      document.addEventListener('resize', calculateContentOffset, false);
    }
    return () => {
      if (typeof window !== 'undefined') {
        document.removeEventListener('mousedown', handleMouseDown, false);
        document.removeEventListener('resize', calculateContentOffset, false);
      }
    };
  }, []);

  React.useEffect(() => {
    calculateContentOffset();
  }, [elementBounding]);

  React.useEffect(() => {
    toggle && calculateContentOffset();
    // isMobile && toggle && setModalState(true);
    return () => {
    };
  }, [toggle]);

  const onHandleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <StyledFilterDropdownContainer
        ref={dropdownRef}
        className="filter-dropdown-container"
        active={toggle}
      >
        <StyledFilterDropdownButton
          onClick={onHandleToggle}
          className="filter-dropdown-button"
          isActive={toggle}
        >
          <TextCustom {...labelTextStyle}>
           {label}
          </TextCustom>
          <StyledIcon className={`filter-dropdown-icon ${toggle && 'active'}`}>
            <div className={`animation ${toggle && 'active'}`}>
              <ChevronDown />
            </div>
          </StyledIcon>
        </StyledFilterDropdownButton>
        {toggle && (
          <>
            <StyledFilterDropdownContent
              className="filter-dropdown-content"
              ref={dropdownContentRef}
              elementBounding={elementBounding}
            >
              <div className='filter-dropdown-options-wrapper'>
              {children}
              </div>
            </StyledFilterDropdownContent>
          </>
        )}
      </StyledFilterDropdownContainer>
    </>
  );
};

export default FilterContentDropdown;
