import {Breakpoints, styled} from '@vw-marketing/us-components';

export const StyledMastheadAppMainContainer = styled.div<any>`
  ${() => `
    position: relative;
    display: flex;
    margin-bottom: 300px;
    @media (min-width: ${Breakpoints.b960}px) {
      margin-bottom: 0;
    }
  `}
`;

export const StyledMastheadImageWrapper = styled.div`
  .background-wrapper_container {
    width: 100%;
    height: auto;
  }
  width: 100%;
  img {
    width: 100%;
  }
`;

export const StyledMastheadContentContainer = styled.div<any>`
  ${({theme}) => `
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  top: 30%;
  @media (min-width: ${Breakpoints.b560}px) {
    // padding-left: ${theme.size.grid001};
    // padding-right: ${theme.size.grid001};
    top: 50%;

  }
  @media (min-width: ${Breakpoints.b960}px) {
    flex-direction: row;
    align-items: baseline;
    padding-left: ${theme.size.grid002};
    padding-right: ${theme.size.grid001};
    top: 30%;
  }
  @media (min-width: ${Breakpoints.b1280}px) {
  }
  `}
`;

export const StyledMastheadContentWrapper = styled.div<any>`
  ${({theme, alignment}) => `
  position: relative;
  width: 100%;
  display: flex;
  height: auto;
  padding-bottom: 87px;
  align-items: center;
  justify-content: center;
  @media (min-width: ${Breakpoints.b560}px) {

  }
  @media (min-width: ${Breakpoints.b960}px) {
   align-items: baseline;
   justify-content: left;
    width: 80%;
    padding-bottom: 0;

  }
  @media (min-width: ${Breakpoints.b1280}px) {
    padding-right: ${theme.size.grid001};
    align-self: ${alignment ? alignment : 'start'};
  }
  @media (min-width: ${Breakpoints.b1600}px) {
    padding-right: 25%;
  }
  @media (min-width: ${Breakpoints.b1920}px) {
    padding-right: 35%;
  }

`}
`;

export const StyledMastheadSearchWrapper = styled.div`
  ${({theme}) => `
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1;
  @media (min-width: ${Breakpoints.b960}px) {
    justify-content: flex-start;
    width: 75%;
    justify-self: end;
    padding-left: ${theme.size.grid004};

  }
  @media (min-width: ${Breakpoints.b1280}px) {
    width: 50%;
  }
`}
`;
