import {
  Richtext,
  TextColor,
  TextCustom,
} from '@vw-marketing/us-components';
import React from 'react';
import { subTitleTextStyle, titleTextOverrides, descriptionTextStyle } from './helpers';
import {
  StyledDescriptionContainer,
  StyledSubTitleTextWrapper,
  StyledTitleTextWrapper,
  StyledDescriptionTextWrapper,
} from './styles';

interface MastheadDescriptionProps {
  subTitle?: string;
  title?: string;
  description?: string;
  textColor?: string;
  cssStyling?: string;
}

const MastheadDescription: React.FC<MastheadDescriptionProps> = (props) => {
  const {title, subTitle, description, textColor, cssStyling} = props;
  return (
    <StyledDescriptionContainer
      className="masthead-description_container"
      textColor={textColor || "#FFF"}
      cssStyle={cssStyling}
    >
      <StyledSubTitleTextWrapper className="masthead-description_subtitle-wrapper">
        <TextCustom {...subTitleTextStyle}>{subTitle}</TextCustom>
      </StyledSubTitleTextWrapper>
      <StyledTitleTextWrapper className="masthead-description_title-wrapper">
        <Richtext
          markdown={title || ''}
          overrides={titleTextOverrides}
          dangerouslyParseRawHTML
          color={TextColor.inherit}
        />
      </StyledTitleTextWrapper>
      <StyledDescriptionTextWrapper className="masthead-description_description-wrapper">
        <TextCustom {...descriptionTextStyle}>{description}</TextCustom>
      </StyledDescriptionTextWrapper>
    </StyledDescriptionContainer>
  );
};

export default MastheadDescription;
