import {
  TextCustomProps,
  TextAppearance,
  TextSize,
  TextLineHeight,
  TextWeight,
  TextColor,
  TextTag,
  createTagOverride,
  TextCustom,
  Breakpoints,
  TextType,
} from '@vw-marketing/us-components';
import StrongCustom from './strong-custom';

export const subTitleTextStyle: TextCustomProps = {
  appearance: TextAppearance.headline200,
  style: {
    [Breakpoints.default]: {
      fontSize: TextSize.TextSize14,
      lineHeight: TextLineHeight.TextLineHeight36,
      fontWeight: TextWeight.bold,
      color: TextColor.inherit,
    },
    [Breakpoints.b1280]: {
      fontSize: TextSize.TextSize16,
      lineHeight: TextLineHeight.TextLineHeight36,
      fontWeight: TextWeight.bold,
      color: TextColor.inherit,
    },
  },
  tag: TextTag.h2,
};
const titleTextStyle: TextCustomProps = {
  appearance: TextAppearance.headline200,
  style: {
    [Breakpoints.default]: {
      fontType: TextType.Headline,
      fontSize: TextSize.TextSize44,
      lineHeight: TextLineHeight.TextLineHeight48,
      color: TextColor.inherit,
      fontWeight: TextWeight.light,
    },
    [Breakpoints.b1280]: {
      fontSize: TextSize.TextSize60,
      lineHeight: TextLineHeight.TextLineHeight68,
      color: TextColor.inherit,
      fontWeight: TextWeight.light,
    },
  },
  tag: TextTag.h1,
};

export const descriptionTextStyle: TextCustomProps = {
  appearance: TextAppearance.copy100,
  style: {
    fontSize: TextSize.TextSize16,
    lineHeight: TextLineHeight.TextLineHeight24,
    color: TextColor.inherit,
  },
  tag: TextTag.p,
};
export const titleTextOverrides = {
  p: createTagOverride(TextCustom, titleTextStyle),
  div: createTagOverride(TextCustom, titleTextStyle),
  span: createTagOverride(TextCustom, titleTextStyle),
  h1: createTagOverride(TextCustom, titleTextStyle),
  strong: createTagOverride(StrongCustom, {})
};
