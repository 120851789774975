import React from 'react';
import ReactPlayer from 'react-player';
import {ErrorMessage} from '../error-message';
import {VideoWrapper} from './styles';
import {useStringifiedServiceConfigsServiceConfig} from '../../hooks/use-stringified-service-configs-service-config';
import {useFetchScene7Video} from '../../hooks/use-fetch-scene7-video';
import {CustomImage} from '@ngw6/us-components';
import {AppConstants} from '../../utils/app-constants';
import {Scene7Video} from '../../typing/main';

interface VideoBackgroundProps {
  readonly videoUrl?: string;
}

const VideoBackground: React.FC<VideoBackgroundProps> = (props) => {
  const {videoUrl} = props;
  const [videoError, setVideoError] = React.useState(false);
  const serviceConfigsServiceConfig =
    useStringifiedServiceConfigsServiceConfig();
  const serviceJson = JSON.parse(serviceConfigsServiceConfig as string);
  const originUrl = serviceJson.urlOrigin;
  const {data, loading, error} = useFetchScene7Video(videoUrl, originUrl);
  let video: Scene7Video | undefined = undefined;

  if (loading) {
    return (
      <CustomImage
        alt={AppConstants.PlaceHolder960x432}
        srcPlaceholder={AppConstants.PlaceHolder960x432}
        srcErrorImage={AppConstants.PlaceHolder960x432}
        src={AppConstants.PlaceHolder960x432}
      />
    );
  }

  // Get the wider video (dx)
  if (data && data?.length > 0) {
    video = data.reduce((prev, current) =>
      prev.dx > current.dx ? prev : current,
    );
  }

  if (error || videoError || !videoUrl) {
    console.log('error: ', error);
    return <ErrorMessage message2="Could not load video" />;
  }

  return (
    <VideoWrapper className="video-background_wrapper">
      <ReactPlayer
        className="video-background"
        url={video && video?.scene7VideoSrc ? video.scene7VideoSrc : ''}
        loop={true}
        muted={true}
        playing={true}
        height="100%"
        width="100%"
        onError={() => setVideoError(true)}
      />
    </VideoWrapper>
  );
};

export default VideoBackground;
