import { Breakpoints, styled } from '@vw-marketing/us-components';

interface DropdownState {
  active: boolean;
  isSticky: boolean;
  modalActive: boolean;
}
export const StyledFilterDropdownContainer: any = styled.div<DropdownState>`
  ${({ active }) => `
  @media (min-width: ${Breakpoints.b560}px) {
    z-index:${active ? '10001' : '0'};
    width: auto;
  }


`}
`;

export const StyledFilterDropdownButton: any = styled.button`
  ${({  }) => `
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  position: relative;
  z-index: 0;
  background: rgba(255, 255, 255, 0.85);
  border: 1px solid rgb(32, 58, 102, 0.1);
  border-radius: 2px;
  color: #001E50;
  padding-left: 24px;
    padding-right: 14px;
  `}
`;

export const StyledFilterDropdownContent: any = styled.div<any>`
  ${({ }) => `
    transform: translateY(100%);
    background: #fff;
    height: inherit;
    overflow-y: visible;
    z-index: 1;
    position: relative;
    border-radius: 2px;

    .filter-dropdown-options-wrapper {
      box-sizing: border-box;
      position: absolute;
      list-style-type: none;
      margin-top: 0px;
      padding-left: 0px;
      border-top: none;
      width: 100%;
      z-index: 1;
      box-shadow: 0px 0px 8px 4px rgba(187, 187, 187, 0.25);
    }
  `}
`;

export const StyledFilterCloseButtonWrapper: any = styled.div<any>`
  ${() => `
  position: fixed;
  display: flex;
  width: 44px;
  height: 44px;
  padding: 10px;
  border-radius: 32px;
  background-color: #dfe4e8;
  z-index: 1002;
  margin-top: 10px;
  margin-right: 10px;
  align-items: center;
  justify-content: center;
  top:0;
  right: 0;

  @media (min-width: ${Breakpoints.b560}px) {
    display: none;
  }
  `}
`;

// the icon needs to be moved down by the size of the wrapper's top padding plus the size of the select's top padding
export const StyledIcon: any = styled.span`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2px;
  pointer-events: none;
  .animation svg {
    transition: transform 0.5s ease;
    transform: rotate(0deg);
  }
  .animation.active svg {
    transform: rotate(180deg);
  }
`;

export const StyledButtonOnModalActive: any = styled.div<any>`
  ${({ isSticky, modalActive }) => `
  position: fixed;
  left: ${isSticky && !modalActive ? '-31px' : '0'};
  right: 0;
  bottom: ${isSticky && !modalActive ? 'calc(-1 * (100vh - 152px))' : '0'};
  margin: 34px 0 0;
  width: 100vw;
  height: 96px;
  padding: 26px 24px 26px 0;
  box-shadow: 0 -2px 9px 0 rgba(224, 224, 224, 0.5);
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  z-index: 1003;
  @media (min-width: ${Breakpoints.b560}px) {
    display: none;
  }
  `}
`;

export const StyledFilterBarTabletContainer: any = styled.div<any>`
  ${({ theme, type }) => `
  display: none;
  @media (min-width: ${Breakpoints.b560}px) {
    display: block;
    width: 100vw;
    width: calc(100vw - ${theme.size.grid004});
    background: #fff;
    position: relative;
    right: 0;
    ${type === 'feature' ? `padding-left: ${theme.size.grid002};` : ''}
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    display: none;
  }
  `}
`;

export const StyledFilterModalLabelContainer: any = styled.div<any>`
  ${({}) => `
  position: fixed;
  z-index: 10003;
  top: 50px;
  @media (min-width: ${Breakpoints.b560}px) {
    display: none;
  }
  `}
`;
