import {styled} from '@vw-marketing/us-components';

export const StyledCode: any = styled.div`
  border-right: 2px solid;
  font-size: 26px;
  padding: 0 15px 0 15px;
  text-align: center;
`;

export const StyledMessage: any = styled.div`
  font-size: 18px;
  text-align: center;
  padding: 10px;
`;

export const StyledContainer: any = styled.div`
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

interface StyledWrapperModel {
  readonly customHeight?: string;
}
export const StyledWrapper: any = styled.div<StyledWrapperModel>`
  height: ${(props: StyledWrapperModel) =>
    props.customHeight ? props.customHeight : '300px'};
`;
