import {Text, TextAppearance, styled, Breakpoints} from '@ngw6/us-components';
import React from 'react';
import {SpinnerIcon} from '../../components/icons/spinner-icon';

interface LoaderModalProps {
  readonly label?: string;
}

export const StyledModalWrapper: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const StyledSpinnerContainer: any = styled.div`
  display: inline-block;
`;

export const StyledSpinnerWrapper: any = styled.div`
  display: flex;
  background: #fff;
  padding: 50px 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 25px 1px rgba(0, 0, 0, 0.2);

  & > :first-child {
    margin-bottom: 20px;
  }
`;

export const StyledLoaderSpinnerWrapper = styled.div<any>`
  z-index: 10001;
  position: absolute;

  ${({theme, isSticky}) => `
    > .modal-spinner-wrapper {
      width: 100vw;
      height: 100vh;

      @media (min-width: ${Breakpoints.b560}px) {
        ${
          isSticky
            ? `
              left: calc(-1 * ${theme.size.grid002});
              top: calc(-1 * var(--sticky-bar-height-down));
            `
            : ''
        }
      }
    }
  `}
`;

export const LoaderSpinnerModal: React.FC<LoaderModalProps> = (
  props,
): JSX.Element => {
  const {label} = props;

  return (
    <StyledModalWrapper className="modal-spinner-wrapper">
      <StyledSpinnerContainer>
        <StyledSpinnerWrapper>
          <SpinnerIcon iconSize={60} />
          <Text appearance={TextAppearance.copy200}>
            {!label ? label : 'Loading...'}
          </Text>
        </StyledSpinnerWrapper>
      </StyledSpinnerContainer>
    </StyledModalWrapper>
  );
};
