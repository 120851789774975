import isEmpty from 'lodash/isEmpty';
import queryString from 'query-string';
import {History} from 'history';

/**
 * Get the correct format for the searh from router history
 * @param history Router history
 */
export const getHistorySearch = (history: History) => {
  const historyAux: any = history;
  let search = history.location.search
    ? history.location.search
    : historyAux.historyMultiplexer.rootHistory.location.search;
  const query = queryString.parse(decodeURI(search));
  delete query['---'];
  search = isEmpty(query) ? '' : `?${queryString.stringify(query)}`;

  return search.replace(/-app/gi, '');
};

/**
 * Get a zip using the location search from react
 * @param history React history
 */
export const getUrlZip = (history: History): string => {
  const search = getHistorySearch(history);
  const query: any = queryString.parse(decodeURI(search));
  if (!isEmpty(query) && query.zip) {
    const validZipRegex = /^[0-9]{5}$/;
    if (validZipRegex.test(query.zip)) {
      return query.zip;
    }
  }

  return '';
};

/**
 * Add a query parameter to the url
 * @param history React history
 * @param key Key for the query parameter in the url
 * @param value Value for the query parameter in the url
 */
export const addUrlParam = (history: History, key: string, value: string) => {
  const search = getHistorySearch(history);
  const query = queryString.parse(decodeURI(search));
  const param = {...query, [key]: value};
  const path = `${history.location.pathname}?${queryString.stringify(param)}`;
  history.replace(path);
};
