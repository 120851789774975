import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router';
import {useApolloClient} from '@apollo/client';
import {useZipData, ZipContext} from '../hooks/use-zip-data';
import {ZipConfig} from '../typing/zip-types';
import {useFeatureServices} from '../hooks/use-feature-services';
import {getUrlZip} from '../utils/navigation-url';
import {LoaderSpinnerModal} from '../components/loader-spinner';
import {addUrlParam} from '../utils/navigation-url';
import {
  onVWBasicErrorMessageLoad,
  onVWBasicLayerloadEvent,
  onVWBasicLinkClickEvent,
} from '../tagging/tagging-helpers';
import {useTrackingManager} from '../hooks/use-tracking-manager';
import {GET_ZIP_FROM_COORD, VALIDATE_ZIP} from '../store/queries/zip';
import {ZipManagerV1} from '@volkswagen-onehub/zip-manager';
import isBrowser from 'is-browser';
import {ModalZip} from '@ngw6/us-components';
import {useTranslations} from '../hooks/use-translations';
import {AppConstants} from '../utils/app-constants';
import {globalModelData, modelsConfigVar} from './local-storage';

export interface ZipHandlerProps {
  children: React.ReactNode;
}

export const ZipHandler: React.FC<ZipHandlerProps> = (
  props: ZipHandlerProps,
) => {
  const {children} = props;
  const translations = useTranslations().modals.modalZip;

  let zipConfig: ZipConfig = {
    zip: '',
    zipError: false,
    zipSubmited: false,
    activeZipModal: false,
    city: '',
    state: '',
  };

  const history = useHistory();

  const zipManager: ZipManagerV1 = useFeatureServices()['zip-manager'];

  const trackingManager = useTrackingManager();

  zipConfig.activeZipModal = zipManager.getZipModalActive();

  const savedZip: string = isBrowser
    ? zipManager.getZipCookie()
    : zipManager.getZip();

  if (savedZip !== '') {
    zipConfig.zip = savedZip;
    zipConfig.zipSubmited = true;
    zipManager.setZip(savedZip);
    zipManager.setZipModalActive(false);
    addUrlParam(history, 'zip', savedZip);

    const savedZipAddress: string = isBrowser
      ? zipManager.getZipCookieAddress()
      : zipManager.getCity() != '' && zipManager.getState() != ''
      ? `${zipManager.getCity()},${zipManager.getState}`
      : '';

    if (savedZipAddress != '') {
      let zipA = savedZipAddress.split(',');
      if (zipA[0] && zipA[0] != '') {
        zipManager.setCity(zipA[0]);
        zipConfig.city = zipA[0];
      }
      if (zipA[1] && zipA[1] != '') {
        zipManager.setState(zipA[1]);
        zipConfig.state = zipA[1];
      }
    }
  } else {
    zipConfig.activeZipModal = true;
    zipManager.setZipModalActive(true);
  }

  const [zipData, setZipData] = useState<ZipConfig>(zipConfig);
  const [zipSuggested, setZipSuggested] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [urlZip] = useState(getUrlZip(history));

  const client = useApolloClient();
  const servicesConfig = useFeatureServices()['service-config-provider'];

  const updateAppZipData = (zipDataParam: ZipConfig) => {
    zipManager.setZipModalActive(zipDataParam.activeZipModal);
    if (isBrowser) {
      zipManager.setZipCookie(zipDataParam.zip);
      zipManager.setZipCookieAddress(
        zipDataParam.city + ', ' + zipDataParam.state,
      );
    } else {
      zipManager.setZip(zipDataParam.zip);
      zipManager.setCity(zipDataParam.city);
      zipManager.setState(zipDataParam.state);
    }

    setZipData(zipDataParam);
  };

  const updateIsLoading = (value) => {
    setIsLoading(value);
  };

  useEffect(() => {
    async function fetchZipSuggestion() {
      //Set zip suggestion following url, coordinates, iptozip(ssr if we are going to enable)
      if (urlZip !== '') {
        setZipSuggested(urlZip);
      } else {
        let coordZip = '';
        zipManager
          .detectUserCoodinates()
          .then((result) => {
            if (result.status) {
              let getZipByCoords = async () => {
                const {data, error} = await client.query({
                  query: GET_ZIP_FROM_COORD,
                  variables: {
                    lat: result.coordinates.lat,
                    lng: result.coordinates.lng,
                  },
                });

                if (!error) {
                  if (data && data.zipFromCoordinates.valid) {
                    setZipSuggested(data.zipFromCoordinates.zip);
                    coordZip = data.zipFromCoordinates.zip;
                  }
                } else {
                  console.log('ERROR: Zip validation, status: ', error);
                }
              };
              getZipByCoords();
            }
          })
          .catch((e) => {
            console.log('Errors fetching detectBrowserLocation', e);
          })
          .finally(() => {
            if (coordZip == '') {
              //get iptozip
              zipManager
                .getZipByIp(true, servicesConfig.configs.iptozip)
                .then((zipIpResp) => {
                  if (zipIpResp.status) {
                    setZipSuggested(zipIpResp.response);
                  }
                });
            }
          });
      }
    }

    if (zipManager.getZipModalActive()) {
      fetchZipSuggestion();
      onVWBasicLayerloadEvent(trackingManager);
    }
  }, []);

  return (
    <ZipContext.Provider
      value={{
        zipData,
        updateAppZipData,
        zipSuggested,
        isLoading,
        updateIsLoading,
      }}
    >
      {children}
      {isLoading && <LoaderSpinnerModal />}
      {zipData && zipData.activeZipModal && (
        <ModalZip
          active={zipData.activeZipModal}
          privacyContentLink={AppConstants.privacyContentLink}
          validateZipQuery={VALIDATE_ZIP}
          translations={translations}
          zipManager={zipManager}
          useZipData={useZipData}
          trackingErrorCallback={(errorId: string) =>
            onVWBasicErrorMessageLoad(
              trackingManager,
              modelsConfigVar(),
              globalModelData(),
              errorId,
            )
          }
          trackingClickCallback={() =>
            onVWBasicLinkClickEvent(trackingManager, 'ZIP Layer', {
              url: AppConstants.privacyContentLink,
              name: translations.privacyLinkCta,
              newTab: true,
            })
          }
        />
      )}
    </ZipContext.Provider>
  );
};
