import {
  Breakpoints,
  TextAlignment,
  TextAppearance,
  TextColor,
  TextCustomProps,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';

export const labelTextStyle: TextCustomProps = {
  appearance: TextAppearance.headline200,
  style: {
    [Breakpoints.default]: {
      color: TextColor.inherit,
      fontSize: TextSize.TextSize14,
      lineHeight: TextLineHeight.TextLineHeight24,
      textAlign: TextAlignment.center,
      fontWeight: TextWeight.regular,
    },
    [Breakpoints.b560]: {
      textAlign: TextAlignment.left,
    },
  },
  tag: TextTag.span,
};

export const labelStickyTextStyle: TextCustomProps = {
  appearance: TextAppearance.headline200,
  style: {
    [Breakpoints.default]: {
      color: TextColor.inherit,
      fontSize: TextSize.TextSize12,
      lineHeight: TextLineHeight.TextLineHeight24,
      textAlign: TextAlignment.center,
      fontWeight: TextWeight.regular,
    },
    [Breakpoints.b560]: {
      textAlign: TextAlignment.left,
    },
  },
  tag: TextTag.span,
};

export const modalButtonTextStyle: TextCustomProps = {
  appearance: TextAppearance.copy100,
  tag: TextTag.span,
  style: {
    color: TextColor.inherit,
    fontSize: TextSize.TextSize14,
    lineHeight: TextLineHeight.TextLineHeight16,
    textAlign: TextAlignment.center,
    fontWeight: TextWeight.regular,
  },
};

export const modalLabelTextStyle: TextCustomProps = {
  appearance: TextAppearance.headline200,
  tag: TextTag.h2,
  style: {
    color: TextColor.primary,
    fontSize: TextSize.TextSize32,
    lineHeight: TextLineHeight.TextLineHeight36,
    textAlign: TextAlignment.left,
    fontWeight: TextWeight.bold,
  },
};
