import { Breakpoints, styled } from '@ngw6/us-components';

export const StyledSpinnerContainer: any = styled.div`
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  @media (min-width: ${Breakpoints.b560}px) {
    min-height: 500px;
    padding: 30px;
  }
`;

export const StyledLoaderWrapper: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  border: solid 1px #ccc;
  width: 100%;

  & > :first-child {
    margin-right: 10px;
  }

  @media (min-width: ${Breakpoints.b560}px) {
    padding: 40px;
  }
`;

export const TextWrapper: any = styled.div`
  width: auto;
`;
