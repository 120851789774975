import React from 'react';
import {Router} from 'react-router-dom';
import {FeatureAppDefinition} from '@feature-hub/core';
import {ReactFeatureApp} from '@feature-hub/react';
import {ThemeProvider} from '@ngw6/us-components';
import {featureAppMemoryStoreDefinition} from './store/feature-app-memory-store';
import {FeatureAppEnvironmentContext} from './hooks/use-feature-app-environment';
import {UniversalState} from './hooks/use-universal-state';
import {FeatureAppConfig, FeatureAppDependencies} from './typing/main';
import {DataServer} from './store/data-server';
import {defineZipManager} from '@volkswagen-onehub/zip-manager';
import {ZipHandler} from './store/zip-handler';
import {InventoryContext} from './hooks/use-inventory';
import {defaultTranslation} from './config/translations';
import {getPriceData} from './utils/configuration';
import MastheadApp from './app';

export const featureAppDefinition: FeatureAppDefinition<
  ReactFeatureApp,
  FeatureAppDependencies,
  FeatureAppConfig
> = {
  dependencies: {
    featureServices: {
      's2:history': '^2.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'car-configuration': '^2.0.0',
      'disclaimer-manager': '^1.1.0',
      'feature-app-memory-store': '^1.0.0',
      'featureapp-service': '^1.3.0',
      'layer-manager': '^2.3.0',
      'locale-service': '^1.0.0',
      'navigation-service': '^1.1.0',
      'service-config-provider': '^1.0.0',
      'showroom-navigation': '^1.1.0',
      'single-configuration-flow-navigator': '^1.0.0',
      'zip-manager': '^1.2.1',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:async-ssr-manager': '^1.0.0',
      's2:logger': '^1.0.0',
      's2:server-request': '^1.0.0',
      'breadcrumb-service': '^1.0.0',
      'compare': '^2.0.0',
      'data-version-service': '^1.0.0',
      'fa-content-service': '^1.0.0',
      'filter-service': '^2.0.0',
      'page-info-service': '^1.0.0',
      'tracking': '^1.0.0',
    },
  },

  ownFeatureServiceDefinitions: [
    featureAppMemoryStoreDefinition,
    defineZipManager(),
  ],

  create: (env) => {
    const {featureServices} = env;

    const {
      's2:serialized-state-manager': serializedStateManager,
      'feature-app-memory-store': memoryStore,
      's2:history': historyService,
      'service-config-provider': serviceConfig,
      'locale-service': localeService,
    } = featureServices;

    const history = historyService.history;
    console.log(serviceConfig.configs);

    const serializedState = serializedStateManager.getSerializedState();

    if (serializedState) {
      const universalState: UniversalState = JSON.parse(serializedState);

      if (!memoryStore.getState()) {
        memoryStore.setState(universalState);
      }
    }

    serializedStateManager.register(() =>
      JSON.stringify(memoryStore.getState()),
    );

    const priceData = getPriceData(localeService);

    return {
      undefined,
      render: () => (
        <>
          <FeatureAppEnvironmentContext.Provider value={env}>
            <InventoryContext.Provider
              value={{translations: defaultTranslation, priceData}}
            >
              <DataServer>
                <Router history={history}>
                  <ZipHandler>
                    <ThemeProvider theme="main">
                      <MastheadApp />
                    </ThemeProvider>
                  </ZipHandler>
                </Router>
              </DataServer>
            </InventoryContext.Provider>
          </FeatureAppEnvironmentContext.Provider>
        </>
      ),
    };
  },
};

export default featureAppDefinition;
